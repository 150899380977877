@use 'kendo-font-icons/scss/_font.scss' as *;
@use 'kendo-font-icons/scss/_variables.scss' as *;
@use 'kendo-font-icons/scss/_icon-list.scss' as *;

$ki-font-url: 'https://cdn-maximizer-dev.azureedge.net/assets/fonts/kendo-icons/kendo-font-icons.ttf';
$ki-font-local-url: '^assets/fonts/kendo-icons/kendo-font-icons.ttf';

$material-icon: (
  'add': '\e145',
  'arrow-back-ios': '\e5e0',
  'arrow-drop-down': '\e5c5',
  'arrow-drop-up': '\e5c7',
  'arrow-forward-ios': '\e5e1',
  'attachment': '\e2bc',
  'bookmark': '\e866',
  'bookmark-border': '\e867',
  'business': '\e0af',
  'call': '\e0b0',
  'cancel': '\e5c9',
  'caps-lock': '\e318',
  'change-date': '\e742',
  'chat': '\e0b7',
  'checkbox-done': '\e86c',
  'checkbox-empty': '\e836',
  'circle': '\ef4a',
  'close': '\e5cd',
  'cloud-sync': '\eb5a',
  'content-copy': '\e14d',
  'date-picker': '\e916',
  'delete': '\e872',
  'down': '\e5db',
  'drag-handle': '\e25d',
  'edit': '\e3c9',
  'error': '\e001',
  'email': '\e0be',
  'expand-less': '\e5ce',
  'expand-more': '\e5cf',
  'export': '\e2c4',
  'export-alt': '\e8d7',
  'filter': '\e152',
  'filter-alt': '\ef4f',
  'folder': '\e2c7',
  'forum': '\e0bf',
  'group': '\e7ef',
  'help': '\e8fd',
  'home': '\e88a',
  'kanban': '\eb7f',
  'launch': '\e895',
  'list': '\e0ee',
  'location': '\e0c8',
  'integration-instructions': '\ef54',
  'menu': '\e5d2',
  'more-vertical': '\e5d4',
  'new-company': '\e729',
  'new-person': '\ea4d',
  'next-plan': '\ef5d',
  'navigate-next': '\e409',
  'navigate-before': '\e408',
  'north': '\f1e0',
  'note-add': '\e89c',
  'notifications': '\e7f4',
  'paid': '\f041',
  'pdf': '\e415',
  'person': '\e7fd',
  'pin': '\f10d',
  'playlist-add': '\e03b',
  'profile': '\e853',
  'print': '\e8ad',
  'quick-action': '\e5ca',
  'remove': '\e15b',
  'repeat': '\e040',
  'reset-alt': '\f053',
  'save': '\e161',
  'schedule': '\e8b5',
  'search': '\e8b6',
  'settings': '\e8b8',
  'share': '\e80d',
  'sort': '\e164',
  'south': '\f1e3',
  'square': '\eb36',
  'sync-alt': '\ea18',
  'tag': '\e54e',
  'task-add': '\f23a',
  'tips-and-updates': '\e79a',
  'today': '\e8df',
  'up': '\e5d8',
  'upload': '\e2c3',
  'upload-alt': '\e2c6',
  'viewlist': '\e8ef',
  'view-week': '\e8f3',
  'visibility-off': '\e8f5',
  'visibility-on': '\e8f4',
  'website': '\e80b',
);

$fontawesome-icon: (
  'a': '\41',
  'address-book': '\f2b9',
  'arrow-up': '\f062',
  'arrow-up-left': '\e091',
  'arrow-up-right': '\e09f',
  'arrow-down': '\f063',
  'arrow-down-to-line': '\f33d',
  'arrow-up-right-from-square': '\f08e',
  'arrows-rotate': '\f021',
  'b': '\42',
  'ban': '\f05e',
  'bars': '\f0c9',
  'ballot-check': '\f733',
  'book': '\f02d',
  'book-open-cover': '\e0c0',
  'bullseye-arrow': '\f648',
  'building': '\f1ad',
  'c': '\43',
  'calendar': '\f133',
  'calendar-plus': '\f271',
  'calendar-day': '\f783',
  'calendar-days': '\f073',
  'caret-left': '\f0d9',
  'caret-down': '\f0d7',
  'caret-up': '\f0d8',
  'angle-up': '\f106',
  'angle-down': '\f107',
  'building-user': '\e4da',
  'check': '\f00c',
  'chevron-left': '\f053',
  'chevron-right': '\f054',
  'chevron-up': '\f077',
  'chevron-down': '\f078',
  'circle': '\f111',
  'circle-check': '\f058',
  'circle-exclamation': '\f06a',
  'circle-info': '\f05a',
  'circle-notch': '\f1ce',
  'circle-play': '\f144',
  'circle-plus': '\f055',
  'circle-user': '\f2bd',
  'circle-up': '\f35b',
  'circle-question': '\f059',
  'circle-star': '\e123',
  'circle-x': '\e12e',
  'clock': '\f017',
  'comments': '\f086',
  'comment-dots': '\f4ad',
  'comment-question': '\e14b',
  'copy': '\f0c5',
  'desktop': '\f390',
  'dollar-sign': '\24',
  'do-not-enter': '\f5ec',
  'down-long': '\f309',
  'down-to-line': '\f34a',
  'ellipsis': '\f141',
  'ellipsis-vertical': '\f142',
  'envelope': '\f0e0',
  'envelope-check': '\e4e8',
  'exclamation': '\F12A',
  'eye': '\f06e',
  'eye-slash': '\f070',
  'file': '\f15b',
  'file-excel': '\f1c3',
  'file-image': '\f1c5',
  'file-plus': '\f319',
  'file-powerpoint': '\f1c4',
  'file-pdf': '\f1c1',
  'file-word': '\f1c2',
  'file-zipper': '\f1c6',
  'filters': '\e17e',
  'flask': '\f0c3',
  'folder': '\f07b',
  'gear': '\f013',
  'grip-dots': '\e411',
  'handshake': '\f2b5',
  'hashtag': '\23',
  'hourglass-end': '\f253',
  'hourglass-half': '\f252',
  'hourglass-start': '\f251',
  'id-badge': '\f2c1',
  'input-numeric': '\e1bd',
  'input-text': '\e1bf',
  'key': '\f084',
  'linkedin': '\f08c',
  'list-check': '\f0ae',
  'location-dot': '\f3c5',
  'magnifying-glass': '\f002',
  'map-location-dot': '\f5a0',
  'message': '\f27a',
  'minus': '\f068',
  'mobile-signal-out': '\e1f0',
  'mug-saucer': '\f0f4',
  'note-sticky': '\f249',
  'paperclip': '\f0c6',
  'paper-plane': '\f1d8',
  'pen-to-square': '\f044',
  'pencil': '\f303',
  'pen-circle': '\e20e',
  'phone': '\f095',
  'print': '\f02f',
  'refresh': '\f021',
  'repeat': '\f363',
  'right-from-bracket': '\f2f5',
  'rss': '\f09e',
  'stars': '\f762',
  'paper-plane-top': '\e20a',
  'plus': '\2b',
  'share-nodes': '\f1e0',
  'stopwatch': '\f2f2',
  'spinner': '\f110',
  'square-facebook': '\f082',
  'square-list': '\e489',
  'square-twitter': '\f081',
  'table': '\f0ce',
  'table-cells': '\f00a',
  'tag': '\f02b',
  'tags': '\f02c',
  'thumbs-up': '\f164',
  'thumbtack': '\f08d',
  'toggle-on': '\f205',
  'trash': '\f1f8',
  'trash-can': '\f2ed',
  'triangle-exclamation': '\f071',
  'up-long': '\f30c',
  'up-from-line': '\f346',
  'utensils': '\f2e7',
  'user': '\f007',
  'user-magnifying-glass': '\e5c5',
  'users': '\f0c0',
  'video': '\f03d',
  'whatsapp': '\f232',
  'whistle': '\f460',
  'xmark': '\f00d',
  'youtube': '\f167',
);

$maximizer-icon: (
  'add-case': '\e814',
  'add-call': '\e81b',
  'add-note': '\e818',
  'add-opportunity': '\e811',
  'add-appointment': '\e81a',
  'add-task': '\e81d',
  'case': '\e815',
  'document-linked': '\e830',
  'document-uploaded': '\e82f',
  'note-accounting': '\e84d',
  'note-call-in': '\e84f',
  'note-call-out': '\e84c',
  'note-case': '\e848',
  'note-email': '\e845',
  'note-history': '\e850',
  'note-manual': '\e84e',
  'note-mail-out': '\e84b',
  'note-opportunity': '\e84a',
  'note-other': '\e844',
  'note-task': '\e849',
  'note-timed': '\e846',
  'note-transfer': '\e847',
  'monitor-case': '\e800',
  'recurring-task': '\e896',
  'recurring-appointment': '\e813',
  'save-email': '\e812',
  'saved-email': '\e81c',
  'team-view': '\e831',
  'timeline-summary': '\e82c',
);

$svg-icons: (
  'case':
    'https://cdn-maximizer-dev.azureedge.net/assets/images/outlook/case.svg',
  'docusign':
    'https://cdn-maximizer-dev.azureedge.net/assets/images/docusign.svg',
  'onboarding':
    'https://cdn-maximizer-dev.azureedge.net/assets/images/outlook/onboarding.svg',
  'opportunity-in-progress':
    'https://cdn-maximizer-dev.azureedge.net/assets/images/opportunity-inprogress.svg',
  'saved-email':
    'https://cdn-maximizer-dev.azureedge.net/assets/images/outlook/saved-email.svg',
  'zendesk': 'https://cdn-maximizer-dev.azureedge.net/assets/images/zendesk.svg',
  'sendgrid': 'https://cdn-maximizer-dev.azureedge.net/assets/images/sendgrid.svg',
  'surveymonkey': 'https://cdn-maximizer-dev.azureedge.net/assets/images/surveymonkey.svg',
  'whatsapp-logo': 'https://cdn-maximizer-dev.azureedge.net/assets/images/whatsapp-logo.svg'
);

$icon-sizes: (
  '8': 0.5rem,
  '10': 0.625rem,
  '12': 0.75rem,
  '14': 0.875rem,
  '16': 1rem,
  '18': 1.125rem,
  '20': 1.25rem,
  '22': 1.375rem,
  '24': 1.5rem,
  '40': 2.5rem,
  '56': 3.5rem,
  '72': 4.5rem,
);

@mixin icon-sizes() {
  @each $name, $size in $icon-sizes {
    &.icon-#{$name} {
      font-size: $size;
    }
  }
}

@mixin background-sizes() {
  @each $name, $size in $icon-sizes {
    &.icon-#{$name}:before {
      height: $size;
      width: $size;
      background-size: $size $size;
    }
  }
}

@mixin material-icons() {
  @each $name, $symbol in $material-icon {
    &.icon-#{$name}:before {
      content: '#{$symbol}';
    }
  }
}

@mixin fontawesome-icons() {
  @each $name, $symbol in $fontawesome-icon {
    &.icon-#{$name}:before {
      content: '#{$symbol}';
    }
  }
}

@mixin maximizer-icons() {
  @each $name, $symbol in $maximizer-icon {
    &.icon-#{$name}:before {
      content: '#{$symbol}';
    }
  }
}

@mixin svg-icons() {
  @each $name, $url in $svg-icons {
    &.icon-#{$name}:before {
      background-image: url('#{$url}');
    }
  }
}

@mixin kendo-font-icon-styles() {
  @at-root {
    @font-face {
      font-family: $ki-font-family;
      font-style: normal;
      font-weight: normal;
      src:
        url('#{$ki-font-url}') format('truetype'),
        url('#{$ki-font-local-url}') format('truetype');
    }
  }

  .k-font-icon {
    width: 1em;
    height: 1em;
    outline: 0;
    font-size: $ki-icon-size;
    font-family: $ki-font-family;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    text-decoration: none;
    flex-shrink: 0;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .k-i-none::before {
    display: none !important;
  }

  // Icon sizes
  .k-icon-xs {
    font-size: $ki-icon-size-xs;
  }
  .k-icon-sm {
    font-size: $ki-icon-size-sm;
  }
  .k-icon-md {
    font-size: $ki-icon-size-md;
  }
  .k-icon-lg {
    font-size: $ki-icon-size-lg;
  }
  .k-icon-xl {
    font-size: $ki-icon-size-xl;
  }
  .k-icon-xxl {
    font-size: $ki-icon-size-xxl;
  }
  .k-icon-xxxl {
    font-size: $ki-icon-size-xxxl;
  }

  .k-flip-h {
    transform: scaleX(-1);
  }
  .k-flip-v {
    transform: scaleY(-1);
  }
  .k-flip-h.k-flip-v,
  .k-flip-both {
    transform: scale(-1, -1);
  }

  @each $index, $rotate in $ki-rotate-map {
    .k-rotate-#{$index} {
      transform: rotate(#{$rotate});
    }
  }

  @include kendo-icon-list();
}

.material-icons {
  font-family: 'Google Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  @include icon-sizes();
  @include material-icons();

  &.icon-filled {
    font-weight: 700;
  }
}

.fa-icons {
  font-size: 24px;
  font-family: 'Font Awesome 6 Pro';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;

  @include icon-sizes();
  @include fontawesome-icons();

  &.icon-light {
    font-weight: 300;
  }

  &.icon-solid {
    font-weight: 900;
  }

  &.icon-regular {
    font-weight: 400;
  }

  &.icon-brand {
    font-family: 'Font Awesome 6 Brands';
  }
}

.maximizer-icons {
  font-family: 'Maximizer Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  @include icon-sizes();
  @include maximizer-icons();
}

.svg-icons {
  &::before {
    @apply inline-flex;
    content: '';
  }

  @include background-sizes();
  @include svg-icons();
}

@include kendo-font-icon-styles();
