@use '../../../styles/maximizer.scss' as maximizer;
@use '../../../styles/index.scss';
@use './typographic.scss';

body {
  @apply overflow-hidden;
}

strong {
  @apply font-medium;
}

.max-outlook {
  @apply bg-white flex flex-col h-full;
}

.scrollbar {
  @include maximizer.slimScroll();
  @apply overflow-y-auto;
}

.appointmentItems .k-chip:first-child .k-chip-actions {
  @apply hidden;
}

.appointmentItems .k-chip {
  @apply bg-neutral-20 text-black border-white;

  .k-chip-actions .k-chip-remove-action {
    @apply w-5;
  }
  .k-chip-actions:hover {
    @apply hover:text-neutral-60;
  }
}

// Notification close button
.k-notification-actions {
  @apply mb-auto;
}

.main-page .k-pager-first,
.main-page .k-pager-last {
  @apply hidden;
}

// Hide pagination dropdown
.main-page .k-picker-md {
  @apply hidden;
}

.main-page kendo-datapager {
  @apply mx-2;
}

// Show pagination buttons
.k-pager-numbers {
  display: block !important;
}

:root {
  --kendo-pager-bg: var(--max-neutral-5);
  --kendo-pager-md-padding-x: 0.125rem;
  --kendo-pager-md-padding-y: 0.125rem;
}

.k-pager-numbers-wrap .k-button {
  @apply p-1;
}

kendo-datapager-numeric-buttons {
  @apply my-auto;
}
.k-pager-numbers-wrap {
  @apply mx-auto;
}

.k-link.k-menu-link:hover {
  @apply bg-inserv-10;
}

.k-pager .k-button {
  @apply font-normal text-neutral-90;
}

.k-pager .k-button.k-selected {
  border-bottom: 2px solid var(--max-inserv-100) !important;
  @apply p-0.5 font-medium text-neutral-90 bg-neutral-5;
}

.k-list-ul .k-focus:first-child:hover {
  @apply bg-inserv-10;
}

.compose-entry-multiselect span.k-treeview-toggle {
  @apply hidden;
}
.compose-entry-multiselect .k-treeview-item {
  @apply pl-2;
}

.compose-entry-multiselect .k-treeview-group:not(:first-child) {
  @apply pl-4;
}

.compose-entry-multiselect .k-treeview-leaf {
  @apply py-1;
}
.compose-entry-multiselect .k-treeview-leaf.k-focus {
  @apply shadow-none;
}
.compose-entry-multiselect .k-chip {
  @apply bg-transparent text-neutral-90 border-none w-full;
}

.compose-entry-multiselect .k-chip-actions {
  @apply hidden;
}

.compose-entry-multiselect .k-checkbox.k-disabled {
  @apply bg-neutral-30 border-neutral-30;
}

.max-ng .entry-page .k-tabstrip-items-wrapper .k-item:focus {
  @apply shadow-none;
}

.max-ng .k-switch:disabled,
.max-ng .k-switch.k-disabled {
  @apply pointer-events-auto;
}

.max-ng .k-tooltip:has(.offset-left-tooltip) {
  @apply ml-2;
  .k-tooltip-button {
    @apply m-0;
  }
}

.max-ng .k-popup .k-item:has(.app-version) {
  @apply border-t border-solid border-neutral-20 pt-0.5;
}

.max-ng
  .k-menu-group
  .k-item:has(.app-version, .database)
  > .k-link.k-disabled {
  @apply px-2 py-1 text-neutral-70 text-xxs;
}

.max-ng .k-button.k-icon-button.edit {
  @apply px-0 py-1;
}

.k-menu-link.k-disabled {
  pointer-events: auto;
}

.max-ng .k-dialog-content:has(.confetti) {
  @apply p-0;
}

.max-ng .k-popover-callout.k-callout-n,
.max-ng .k-popover-callout.k-callout-s {
  @apply bg-neutral-5 opacity-100 shadow-card;
}

.max-ng .k-popover-inner {
  @apply shadow-card bg-neutral-5;
}

kendo-popup.email-search {
  z-index: 1000 !important;
}

.k-dialog-content:has(.narrow) {
  @apply p-3 m-0;
}

.k-icon-button.k-button-md.small {
  @apply p-0;
}
